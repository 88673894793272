body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow: hidden;
  touch-action: none;
  overscroll-behavior-y: none;
}

.mapboxgl-canvas {
  outline: none;
}

.maplibregl-ctrl-bottom-left {
  visibility: hidden;
}
